<template>
    <div class="container-fluid animated fadeIn" style="background: rgb(248, 249, 250);">
        <b-row class="app flex-row align-items-center">
            <b-col cols="1" lg="4"></b-col>
            <b-col cols="10" lg="4">
                <b-card title="Account Verification" class="modules-container" header-bg-variant="primary">
                    {{ content }}

                    <b-row>
                        <b-button class="mx-3" style="margin-top: 20px;" type="button" variant="primary" @click="goBack()">Go back</b-button>
                    </b-row>

                </b-card>
            </b-col>
            <b-col cols="1" lg="4"/>
        </b-row>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "AccountVerification",

        data() {
            return {
                content: ""
            }
        },

        beforeRouteEnter(to, from, next) {
            Vue.prototype.$axios.get('/account-verification', {
                params: {
                    token: to.params.token
                }
            }).then(response => {
                next(vm => {
                    vm.content = response.data;
                })
            }).catch(err => {
                next(vm => {
                    vm.content = err;
                })
            });
        },

        methods: {
            goBack() {
                this.$router.push('/');
            }
        }
    }
</script>

<style scoped>

</style>